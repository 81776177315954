import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUser,
  faBook,
  faShoppingCart,
  faBox,
  faSwatchbook,
  faFileAlt,
  faInfoCircle,
  faSignOutAlt,
  faChevronRight,
  faChevronLeft,
  faPhone,
  faBars,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import "./Sidenav.css";

const Sidenav = ({ HandleLogout }) => {
  const [expanded, setExpanded] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isMobile) {
      document.body.classList.toggle("nav-expanded", expanded);
    } else {
      document.body.classList.remove("nav-expanded");
    }
  }, [expanded, isMobile]);

  function onClickRedirect(url, inNextTab = false) {
    if (inNextTab) {
      window.open(url);
    } else {
      window.location.href = url;
    }
  }

  const navItems = [
    {
      id: 1,
      name: "Home",
      isActive: window.location.pathname === "/" ? true : false,
      onClickFunction: () => {
        onClickRedirect("/");
      },
      icon: faHome,
    },
    // {
    //   id: 2,
    //   name: "My Profile",
    //   isActive: window.location.pathname === "/my-profile" ? true : false,
    //   icon: faUser,
    //   onClickFunction: () => {
    //     onClickRedirect("/my-profile");
    //   },
    // },
    // {
    //   id: 3,
    //   name: "My Courses",
    //   isActive: window.location.pathname === "/my-courses" ? true : false,
    //   icon: faBook,
    //   onClickFunction: () => {
    //     onClickRedirect("/my-courses");
    //   },
    // },
    {
      id: 4,
      name: "My Cart",
      isActive: window.location.pathname === "/my-cart" ? true : false,
      icon: faShoppingCart,
      onClickFunction: () => {
        onClickRedirect("/my-cart");
      },
    },
    {
      id: 5,
      name: "My Orders",
      isActive: window.location.pathname === "/my-orders" ? true : false,
      icon: faBox,
      onClickFunction: () => {
        onClickRedirect("/my-orders");
      },
    },
    {
      id: 10,
      name: "My Courses",
      isActive: window.location.pathname === "/my-courses" ? true : false,
      icon: faSwatchbook,
      onClickFunction: () => {
        onClickRedirect("/my-courses");
      },
    },
    {
      id: 6,
      name: "Help And Support",
      isActive: false,
      icon: faPhone,
      onClickFunction: () => {
        onClickRedirect("https://www.drishtiias.com/support-ticket", true);
      },
    },
    {
      id: 7,
      name: "Terms & Conditions",
      isActive:
        window.location.pathname === "/terms-and-condition" ? true : false,
      icon: faFileAlt,
      onClickFunction: () => {
        onClickRedirect("/terms-and-condition");
      },
    },
    {
      id: 8,
      name: "About Us",
      isActive: window.location.pathname === "/about-us" ? true : false,
      icon: faInfoCircle,
      onClickFunction: () => {
        onClickRedirect("/about-us");
      },
    },
    {
      id: 9,
      name: "Logout",
      isActive: false,
      icon: faSignOutAlt,
      onClickFunction: HandleLogout,
    },
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        expanded &&
        !event.target.closest(".sidenav") &&
        !event.target.closest(".mobile-toggle")
      ) {
        setExpanded(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [expanded]);

  return (
    <>
      <div className={`sidenav ${expanded ? "expanded" : ""}`}>
        {navItems.map((item) => (
          <div
            className={`nav-item ${item.isActive ? "active" : ""}`}
            key={item.id}
            onMouseEnter={() => setHoveredItem(item.id)}
            onMouseLeave={() => setHoveredItem(null)}
            onClick={item.onClickFunction}
          >
            <FontAwesomeIcon icon={item.icon} className="icon" />
            <span className="nav-text">{item.name}</span>
            {!expanded && hoveredItem === item.id && (
              <FontAwesomeIcon
                icon={faChevronRight}
                className="chevron-right"
              />
            )}
          </div>
        ))}
        {!isMobile && (
          <button
            className="toggle-button web-toggle"
            onClick={() => setExpanded(!expanded)}
          >
            <FontAwesomeIcon icon={expanded ? faChevronRight : faChevronLeft} />
          </button>
        )}
      </div>
      {isMobile && (
        <button
          className="toggle-button mobile-toggle"
          onClick={() => setExpanded(!expanded)}
        >
          <FontAwesomeIcon icon={expanded ? faTimes : faBars} />
        </button>
      )}
      {isMobile && expanded && (
        <div className="overlay" onClick={() => setExpanded(false)}></div>
      )}
    </>
  );
};

export default Sidenav;
