import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useParams
} from "react-router-dom";
import "./App.css";
import Header from "./Components/Includes/Header/Header";
import Dashboard from "./Components/Dashboard/Dashboard";
import Footer from "./Components/Includes/Footer/Footer";
import ProductInformation from "./Components/ProductInformation/ProductInformation";
import Login from "./Components/Login/Login";
import Sidenav from "./Components/Includes/Sidenav/Sidenav";
import PaymentPage from "./Components/Payments/PaymentPage";
// import PaymentSuccessPage from "./Components/Payments/Essentials/BillSummary/BillSummary";
import MyCart from "./Components/MyCart/MyCart";
import ProductListing from "./Components/ProductListing/ProductListing"
import PaymentSuccess from "./Components/Payments/PaymentSuccess/PaymentSuccess"
import PaymentFailed from "./Components/Payments/PaymentFailed/PaymentFailed"
import ComingSoon from "./Components/ComingSoon/ComingSoon"
import SearchResult from "./Components/SearchResult/SearchResult"
import ExploreResult from "./Components/ExploreResult/ExploreResult"
import MyOrders from "./Components/MyOrders/MyOrders"
import AboutUs from "./Components/AboutUs/AboutUs"
import TermsAndCondition from "./Components/TermsAndCondition/TermsAndCondition"
import TestSeriesInstruction from "./Components/TestSeriesInstruction/TestSeriesInstruction"
// new dashboard
import NewDashboard from "./Components/NewDashboard/NewDashboard";
import MyCourses from "./Components/MyCourses/MyCourses";
import FooterNew from "./Components/NewDashboard/Essentials/Footer/Footer";
import { ApiHitPost } from "./Services/ApiHit";

const App = () => {
  const SelectedVerticalShouldBe =
    localStorage.getItem("dla_web_student_selected_vertical") === null
      ? 0
      : parseInt(localStorage.getItem("dla_web_student_selected_vertical"));
  const [SelectedVertical, SetSelectedVertical] = useState(
    SelectedVerticalShouldBe
  );
  const HandleVerticalSelect = (SelectedVertical) => {
    SetSelectedVertical(parseInt(SelectedVertical.target.value));
    if (localStorage.getItem("dla_web_student_u") !== null) {
      localStorage.setItem(
        "dla_web_student_selected_vertical",
        SelectedVertical.target.value
      );
    }
  };


  return (
    <Router>
      <AppContent
        onVerticalSelect={HandleVerticalSelect}
        SelectedVertical={SelectedVertical}
      />
    </Router>
  );
};

const AppContent = ({ onVerticalSelect, SelectedVertical }) => {
  const location = useLocation();

  const showHeaderAndFooter = location.pathname !== "/login";

  const [isLoggedInUser, setIsLoggedInUser] = useState(false);
  const [LoggedInUserProfilePhoto, setLoggedInUserProfilePhoto] = useState('https://www.drishtiias.com/images/uploads/1721990943_default_user.png');
  const [LoggedInUserFullName, setLoggedInUserFullName] = useState(false);
  const [loading, setLoading] = useState(false);

  const HandleLogout = async () => {
    if(isLoggedInUser){
      try {
        setLoading(true);
        ApiHitPost('/auth/logout')
        .then(response => {
          console.log(response);
          setLoading(false);
          if (response.status === 'SUCCESS') {
            clearLocalStorage();
            setIsLoggedInUser(false);
            window.location.reload();
          }else{
            setLoading(false);
            console.log(response.message || 'Error In Logout!.');
          }
        }).catch(error => {
          setLoading(false);
          console.log(error);
          if (error.response && error.response.status && error.response.status === 401) {
            clearLocalStorage();
            window.location.reload();
          }
        });
      } catch (error) {
        setLoading(false);
        console.log('Unable to Logout ',error);
      }
    }
  }

  function clearLocalStorage() {
    localStorage.removeItem('dla_web_student_u');
    localStorage.removeItem('dla_web_student_r');
    localStorage.removeItem('dla_web_student_first_name');
    localStorage.removeItem('dla_web_student_last_name');
    localStorage.removeItem('dla_web_student_profile_picture');
    localStorage.removeItem('dla_web_student_selected_vertical');
    localStorage.removeItem('dla_web_student_email_id');
    localStorage.removeItem('dla_web_student_gender');
    localStorage.removeItem('dla_web_student_mobile_number');
  }

  const handleLogin = () => {
    const user = localStorage.getItem("dla_web_student_u");
    const profilePicture = localStorage.getItem("dla_web_student_profile_picture");
    const firstName = localStorage.getItem("dla_web_student_first_name");
    const lastName = localStorage.getItem("dla_web_student_last_name");
    setIsLoggedInUser(user !== null);

    if (profilePicture) {
        setLoggedInUserProfilePhoto(profilePicture);
    }

    if (firstName && lastName) {
        setLoggedInUserFullName(`${firstName} ${lastName}`);
    }
  };
  
  useEffect(() => {
    handleLogin();
  }, []);

  // Component to handle redirection logic
  const RedirectToProductInformation = () => {
    const { productId } = useParams();
    window.location.href = `/product-information/${productId}`;
  };
  
  const RedirectToDashboard = () => {
    window.location.href = `/`;
  };

  const RedirectToTermsAndCondition = () => {
    window.location.href = `/terms-and-condition`;
  };
  
  // added for footer by satyam
  const specificUrl = '/sample/lfkvsnl/dashboard/vsdjnvnkdsksvdl/vfksdjnsvdk';

  return (
    <>
      {showHeaderAndFooter && (
        <Header
          SelectedVertical={SelectedVertical}
          onVerticalSelect={onVerticalSelect}
          HandleLogout={HandleLogout}
          LoggedInUserProfilePhoto={LoggedInUserProfilePhoto}
          LoggedInUserFullName={LoggedInUserFullName}
          loading={loading}
          isLoggedInUser={isLoggedInUser}
        />
      )}
      {showHeaderAndFooter && isLoggedInUser && <Sidenav HandleLogout={HandleLogout} />}
      <div className={showHeaderAndFooter ? "logged-in" : ""}>
        <Routes>
          <Route
            path="/"
            element={<Dashboard SelectedVertical={SelectedVertical} />}
          />
          <Route
            path="/index.php/web/Exam_Prep/exam_detail/:productId"
            element={<RedirectToProductInformation />}
          />
          <Route
            path="/estore"
            element={<RedirectToDashboard />}
          />
          <Route
            path="/view-product/:productId"
            element={<RedirectToProductInformation />}
          />
          <Route
            path="/product-information/:productId"
            element={<ProductInformation isLoggedInUser={isLoggedInUser} />}
          />
          <Route path="/login" element={<Login handleLogin={handleLogin} />} />
          {/* <Route path="/bill-summary" element={<Payment />} /> */}
          <Route path="/pay/:productId" element={<PaymentPage initType={1}  />} />
          <Route path="/pay-emi/:productId/:emiNo" element={<PaymentPage initType={2}  />} />
          <Route path="/payment" element={<PaymentPage initType={3}  />} />
          {/* <Route path="/payment-success" element={<PaymentSuccessPage />} /> */}
          <Route path="/my-cart" element={<MyCart />} />
          <Route path="/search" element={<SearchResult isLoggedInUser={isLoggedInUser} />} />
          <Route path="/explore" element={<ExploreResult isLoggedInUser={isLoggedInUser} />} />
          <Route path="/product-list/:groupId" element={<ProductListing isLoggedInUser={isLoggedInUser} />} />
          <Route path="/payment-success" element={<PaymentSuccess />}/>
          <Route path="/payment-failed" element={<PaymentFailed />}/>
          <Route path="/comingsoon" element={<ComingSoon/>}/>
          <Route path="/my-orders" element={<MyOrders  />}/>
          <Route path="/my-courses" element={<MyCourses  />}/>
          <Route path="/about-us" element={<AboutUs  />}/>
          <Route path="/terms-and-condition" element={<TermsAndCondition  />}/>
          <Route path="/test-series-instruction" element={<TestSeriesInstruction  />}/>
          <Route
            path="/index.php/web/home/term_condition"
            element={<RedirectToTermsAndCondition />}
          />
          {/* new dashboared */}
          <Route path="/sample/lfkvsnl/dashboard/vsdjnvnkdsksvdl/vfksdjnsvdk" element={<NewDashboard  />}/>
          
          <Route path="/my-courses" element={<MyCourses />}/>
        </Routes>
      </div>
      
      {showHeaderAndFooter && location.pathname !== specificUrl && <Footer className="logged-in" />}
      {location.pathname === specificUrl && <FooterNew />}
    </>
  );
};



export default App;
