import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./DashboardGroups.css";

const DashboardGroups = ({ GroupInformation }) => {
  const [itemsPerSlide, setItemsPerSlide] = useState(6);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 767) {
        setItemsPerSlide(3);
      } else {
        setItemsPerSlide(6);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const HandleProductClick = (Url) => {
    window.location.href = Url;
  };

  const HandleViewAll = (groupId) => {
    navigate(`/product-list/${groupId}`);
  };

  const Carousel = ({ items }) => {
    const carouselRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    const handleMouseDown = (e) => {
      setIsDragging(true);
      setStartX(e.pageX - carouselRef.current.offsetLeft);
      setScrollLeft(carouselRef.current.scrollLeft);
    };

    const handleMouseLeave = () => {
      setIsDragging(false);
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    const handleMouseMove = (e) => {
      if (!isDragging) return;
      e.preventDefault();
      const x = e.pageX - carouselRef.current.offsetLeft;
      const walk = (x - startX) * 2;
      carouselRef.current.scrollLeft = scrollLeft - walk;
    };

    return (
      <div className="carousel">
        <div 
          className="carousel-container" 
          ref={carouselRef}
          onMouseDown={handleMouseDown}
          onMouseLeave={handleMouseLeave}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
        >
          {items.map((item, index) => (
            <div
              key={index}
              className="carousel-item"
              onClick={() =>
                HandleProductClick(`/product-information/${item.product_id}`)
              }
            >
              <img
                src={item.cover_image}
                alt={`${item.product_title_english}`}
                className="img-fluid"
                draggable="false"
              />
              {/* added by @author Satyam */}
              {/* <div className="ribbon">
                <span>40% OFF</span>
              </div> */}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      {GroupInformation.map((OneGroupInformation, index) => (
        <div key={index} className="course-section">
          <div className="section-header">
            <h3 className="section-title">{OneGroupInformation.group_name}</h3>
            <button
              className="view-all-btn"
              onClick={() => HandleViewAll(OneGroupInformation.group_id)}
            >
              View All
            </button>
          </div>
          <Carousel items={OneGroupInformation.products} />
        </div>
      ))}
    </>
  );
};

export default DashboardGroups;