import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './ProductListing.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faLanguage } from '@fortawesome/free-solid-svg-icons';
import { ApiHitPost } from "../../Services/ApiHit";
import Loader from '../Includes/Loader/Loader'

const ProductListing = ({  isLoggedInUser }) => {
  const navigate = useNavigate();
  const [productListingData, setProductListingData] = useState([]);
  const { groupId } = useParams();
  const [loading, setLoading] = useState(false);

  useEffect(()=>{
    const getProductListingData = () => {
      setLoading(true);
      ApiHitPost('/dashboard/get_products_inside_dashboard_group',{
        group_id: parseInt(groupId)
      }).then((response) => {
        setProductListingData(response.data.group_information);
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        setLoading(false);
      });
    }
    getProductListingData();
  },[])

  const handleViewDetails = (productId) => {
    navigate(`/product-information/${productId}`);
  };

  const handleBuyNowDetails = (productId) => {
    try {
        if (isLoggedInUser) {
            window.location.href = `/pay/${productId}`;
        } else {
            localStorage.setItem('dla_web_student_redirect_back_to_login', `/pay/${productId}`);
            window.location.href = `/login`;
        }
    } catch (error) {
        console.log(error);
    } finally {
        
    }
}
  // added @author Satyam
  const truncateTitle = (title, wordLimit) => {
    const words = title.split(' ');
    if (words.length > wordLimit) {
        return words.slice(0, wordLimit).join(' ') + '...';
    }
    return title;
  };

  
  if (loading) {
    return (
      <Loader />
    );
  }

  return (
    <div className="course-list-container">
      {productListingData.length > 0 && productListingData.map((group) => (
        <div key={group.group_id} className="course-list-wrapper">
          <div className="course-list-header">
            <h2>{group.group_name}</h2>
          </div>
          <div className="course-grid">
            {group.products.map((course) => (
              <div key={course.product_id} className="course-card">
                {/* ribbon added by @author Satyam */}

                 {/* <div className="dla-ribbon">
                  <span className="dla-ribbon-text">40% OFF</span>
                </div> */}

                <div className="course-info">
                  <div className="course-banner">
                    <img src={course.desc_header_image} alt={course.product_title_english} />
                  </div>
                  <div className="course-type">{truncateTitle(course.product_title_english, 3)}</div>
                  <div className="category-main-div">
                    <span className="category-btn">
                      {course.product_category_type_name}
                    </span>
                  </div>
                  <br></br>
                  <div className="course-details">
                    <span>
                      <FontAwesomeIcon icon={faCalendar} style={{ color: '#3e67be' }} /> Validity: {course.validity} Months
                    </span>
                    <span>
                      <FontAwesomeIcon icon={faLanguage} style={{ color: '#3e67be' }} /> Medium: {course.product_lang}
                    </span>
                  </div>
                  <div className="course-pricing">
                    <span className="course-discounted-price">₹{course.product_selling_price}</span>
                    <span className="course-original-price">₹{course.product_mrp}</span>
                  </div>
                  <div className="course-actions">
                    <button
                      className="course-view-details"
                      onClick={() => handleViewDetails(course.product_id)}
                    >
                      View Details
                    </button>
                    <button 
                      className="course-buy-now"
                      onClick={() => handleBuyNowDetails(course.product_id)}
                    >
                      Buy Now
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProductListing;