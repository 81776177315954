import React, { useState, useEffect } from 'react';
import styles from './MyCourses.module.css';
import { ApiHitPost } from "../../Services/ApiHit";
import { useNavigate } from "react-router-dom";

const CourseCard = ({ course }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.courseCard}>
      <div className={styles.bannerSection}>
        <img src={course.cover_image} alt={course.title} className={styles.bannerImage} />
        <div className={styles.bannerContent}>
          <h2>{course.product_title_english}</h2>
          <CourseDetails course={course} navigate={navigate} />
        </div>
      </div>
    </div>
  );
};

const CourseDetails = ({ course, navigate }) => {
  let allEMIsPaid = true;
  let upcoming_emi_no = 0;
  let foundFirstPendingEMI = false; // Flag to track the first pending EMI

  if (course.type === 2) {
    let completedCount = 0;
    let stepsArray = [];

    course.emi_information.forEach((emiDetails) => {
      if (emiDetails.status === 1) {
        completedCount++;
        stepsArray.push("Completed");
      } else {
        stepsArray.push("Pending");
        allEMIsPaid = false;
        
        // Store the first occurrence of status 0
        if (!foundFirstPendingEMI) {
          upcoming_emi_no = emiDetails.emi_no;
          foundFirstPendingEMI = true;
        }
      }
    });

    course.progress = {
      completed: completedCount,
      total: course.emi_information.length,
      steps: stepsArray,
    };
    course.allEMIsPaid = allEMIsPaid;
    course.upcoming_emi_no = upcoming_emi_no; // Added this to ensure it's accessible outside
  }


  

  return (
    <div className={styles.courseDetails}>
      <div className={styles.detailRow}>
        <span>Medium: {course.product_lang}</span>
        <span>Expires on: {new Date(course.valid_till).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })}</span>
        {allEMIsPaid ? (
          <span className={styles.paymentStatus}>Payment: Complete</span>
        ) : (
          <span>Payment: Ongoing</span>
        )}
      </div>
      <div className={styles.detailRow}>
        <span>Category: {course.product_category_type_name}</span>
        <span>Validity: {course.validity} Months</span>
        {/* <span>Purchased on: {course.purchasedOn}</span> */}
      </div>
      {course.progress && <CourseProgress progress={course.progress} />}
      {course.actions && <CourseActions actions={course.actions} />}
      
      {/* View Details Button */}
      <div className={styles.viewDetailsContainer}>
      <button 
        className={styles.viewDetailsButton}
        onClick={() => navigate(`/product-information/${course.product_id}`)}
      >
        View Details
      </button>
      {course.type === 2 && !course.allEMIsPaid && (
      <button 
        className={styles.viewDetailsButton}
        onClick={() => navigate(`/pay-emi/${course.product_id}/${course.upcoming_emi_no}`)}
      >
        Pay {getCustomOrdinalSuffix(course.upcoming_emi_no)} EMI
      </button>
    )}
</div>

    </div>
  );
};

const getCustomOrdinalSuffix = (number) => {
  if (number >= 11 && number <= 13) {
    return `${number}'th`;
  }
  const lastDigit = number % 10;
  switch (lastDigit) {
    case 1:
      return `${number}'st`;
    case 2:
      return `${number}'nd`;
    case 3:
      return `${number}'rd`;
    default:
      return `${number}'th`;
  }
};

const CourseProgress = ({ progress }) => (
  <div className={styles.courseProgress}>
    <div className={styles.progressText}>
      {progress.completed}/{progress.total} EMI'S PAID
    </div>
    <div className={styles.progressSteps}>
      {progress.steps.map((step, index) => (
        <div key={index} className={`${styles.progressStep} ${styles[step.toLowerCase()]}`}>
          <div className={styles.stepDot}></div>
          <div className={styles.stepLabel}>{step}</div>
        </div>
      ))}
    </div>
  </div>
);

const CourseActions = ({ actions }) => (
  <div className={styles.actionButtons}>
    {actions.map((action, idx) => (
      <button key={idx} className={styles.actionButton}>
        <span className={styles.actionIcon}>{action.icon}</span>
        {action.text}
      </button>
    ))}
  </div>
);

const MyCourses = () => {
  const [coursesData, setCoursesData] = useState({ courses: [] });

  useEffect(() => {
    const dla_web_student_u = localStorage.getItem("dla_web_student_u");
    if (!dla_web_student_u) {
      localStorage.setItem('dla_web_student_redirect_back_to_login', `/my-cart`);
      window.location.href = '/login';
    }

    const fetchUserCoursesData = async () => {
      try {
        const userProducts = await ApiHitPost('/user/get_my_products');
        setCoursesData({ courses: userProducts.data });
      } catch (error) {
        console.error('Failed to load courses:', error);
      }
    };

    fetchUserCoursesData();
  }, []);

  return (
    <div className={styles.courseContainer}>
      {coursesData.courses.length > 0 ? (
        coursesData.courses.map((course, index) => (
          <CourseCard key={index} course={course} />
        ))
      ) : (
        <p>No courses available</p>
      )}
    </div>
  );
};

export default MyCourses;
