import React from "react";
import "./AddedAddOns.css";

function AddedAddOns({ mandateAddOns, addedAddOns, onRemoveAddOn }) {
  return (
    <>
      {(mandateAddOns.length > 0 || addedAddOns.length > 0) && (
        <div className="added-addons">
          <h3>Added Add-Ons</h3>
          {mandateAddOns.map((addon) => (
            <div key={addon.id} className="added-addon-item">
              <div className="addon-info">
                <span className="addon-name">{addon.addon_desc}</span>
                <span className="addon-price">₹ {addon.addon_sp}/-</span>
              </div>
              {addon.is_default == 1 && (
                <button
                  className="remove-addon"
                  onClick={() => onRemoveAddOn(addon.id)}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              )}
            </div>
          ))}
          {addedAddOns.map((addon) => (
            <div key={addon.id} className="added-addon-item">
              <div className="addon-info">
                <span className="addon-name">{addon.addon_desc}</span>
                <span className="addon-price">₹ {addon.addon_sp}/-</span>
              </div>
              <button
                className="remove-addon"
                onClick={() => onRemoveAddOn(addon.id)}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
                    fill="currentColor"
                  />
                </svg>
              </button>
            </div>
          ))}
        </div>
      )}
    </>
  );
}

export default AddedAddOns;
