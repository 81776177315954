import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ApiHitPost } from "../../Services/ApiHit";
import Loader from '../Includes/Loader/Loader';

const TestSeriesInstruction = () => {
    const [searchParams] = useSearchParams();

    // Extracting values from URL
    const productId = Number(searchParams.get("product-id")) || 0;
    const testId = Number(searchParams.get("test-series-id")) || 0;
    const tabId = Number(searchParams.get("tab-id")) || 0;
    const is_reattempt = Number(searchParams.get("is-reattempt")) || 0;
    const languageParam = Number(searchParams.get("language")) || 1;

    // Component states
    const [language, setLanguage] = useState(languageParam);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [testDetails, setTestDetails] = useState(null);

    // Fetch test details on component mount
    useEffect(() => {
        const fetchTestDetails = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await ApiHitPost("/test_series/pts/get_test_series_details", {
                    product_id: productId,
                    test_series_id: testId,
                    tab_id: tabId
                });
                setTestDetails(response.data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchTestDetails();
    }, [productId, testId, tabId]);

    // Handle loading and error states
    if (loading) return <Loader />;
    if (error) return <p className="text-red-500 text-center">Error: {error}</p>;

    // Function to open test in new window
    const openTestWindow = async () => {
        const token = localStorage.getItem("dla_web_student_u");
    
        // Change URL without reloading the page
      
        window.history.pushState(null, "", `product-information/${productId}`);
        window.location.href = window.location.href; // Forces a full reload with cache bypass
        
        // const testUrl = `http://localhost:5009/api/v1/test_series/pts/engine?test-series-id=${testId}&product-id=${productId}&is-reattempt=${is_reattempt}&tab-id=${tabId}&internal_redirection=0&language=${language}&token=${token}`;
    
    
        // const testUrl = `https://dla.staging.frontbencher.in/api/v1/test_series/pts/engine?test-series-id=${testId}&product-id=${productId}&is-reattempt=${is_reattempt}&tab-id=${tabId}&internal_redirection=0&language=${language}&token=${token}`;
        const testUrl = `https://www.drishtilearningapp.com/api/v1/test_series/pts/engine?test-series-id=${testId}&product-id=${productId}&is-reattempt=${is_reattempt}&tab-id=${tabId}&internal_redirection=0&language=${language}&token=${token}`;
    
        // Get full screen width and height
        const screenWidth = window.screen.availWidth;
        const screenHeight = window.screen.availHeight;
    
        // Open the test window before making the API call
        let newWindow = window.open(
            testUrl,
            "_blank",
            `width=${screenWidth},height=${screenHeight},top=0,left=0,toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=no`
        );
    
        if (newWindow) {
            let minimizeCount = 0; // Track minimize attempts
    
            // Move and resize to full screen
            newWindow.moveTo(0, 0);
            newWindow.resizeTo(screenWidth, screenHeight);
    
            // Force focus on the new window
            let focusInterval = setInterval(() => {
                if (newWindow.closed) {
                    clearInterval(focusInterval);
                } else {
                    newWindow.focus();
                }
            }, 100);
    
            // Detect if the test window loses focus
            newWindow.onblur = function () {
                minimizeCount++;
    
                if (minimizeCount >= 3) { // Close the test after 3 minimize attempts
                    newWindow.close();
                } else {
                    newWindow.focus();
                }
            };
    
            // Prevent closing the main window until test is complete
            window.onbeforeunload = function () {
                if (!newWindow.closed) {
                    return "You must complete the test before leaving!";
                }
            };
        } else {
            alert("Popup blocked! Please allow pop-ups for this site.");
            return; // Stop execution if the pop-up is blocked
        }
    
        // Now fetch the test URL
        try {
            const response = await fetch(testUrl, {
                method: "GET",
                headers: {
                    "authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                }
            });
    
            if (!response.ok) {
                console.error("Failed to authorize request:", response.statusText);
                alert("Failed to load test. Please try again.");
            }
        } catch (error) {
            console.error("Error fetching the test URL:", error);
        }
    };
    
    
    

    function formatDateTime(isoString) {
        const date = new Date(isoString);
        return date.toLocaleString('en-US', { 
            year: 'numeric', 
            month: 'long', 
            day: 'numeric', 
            hour: '2-digit', 
            minute: '2-digit', 
           
            hour12: true 
        });
    }

    return (
        <div className="p-6 space-y-5">
            {/* Start Date & End Date Section */}
            <div className="flex justify-between items-center bg-gray-100 p-4 rounded-md shadow-md">
                <div className="flex items-center space-x-2">
                    <img src="/ic_calender.png" alt="calendar" className="w-5 h-5" />
                    <div>
                        <p className="text-xs text-gray-500">Start Date</p>
                        <p className="text-sm font-medium">{formatDateTime(testDetails?.test_series_details?.start_date)}</p>
                    </div>
                </div>
                <div className="flex items-center space-x-2">
                    <img src="/ic_calender.png" alt="calendar" className="w-5 h-5" />
                    <div>
                        <p className="text-xs text-gray-500">End Date</p>
                        <p className="text-sm font-medium">{formatDateTime(testDetails?.test_series_details?.end_date)}</p>
                    </div>
                </div>
            </div>

            {/* Test Details Section */}
            <div className="p-5 bg-blue-100 rounded-md shadow-md flex justify-between">
                <div className="flex flex-col items-center">
                    <img src="/ic_question.png" alt="questions" className="w-6 h-6" />
                    <p className="text-sm">{testDetails?.test_series_details?.number_of_questions}</p>
                    <p className="text-xs text-gray-500">Questions</p>
                </div>
                <div className="flex flex-col items-center">
                    <img src="/ic_time.png" alt="time" className="w-6 h-6" />
                    <p className="text-sm">{testDetails?.test_series_details?.time_in_mins}</p>
                    <p className="text-xs text-gray-500">Minutes</p>
                </div>
                <div className="flex flex-col items-center">
                    <img src="/ic_marks.png" alt="marks" className="w-6 h-6" />
                    <p className="text-sm">{testDetails?.test_series_details?.total_marks}</p>
                    <p className="text-xs text-gray-500">Marks</p>
                </div>
            </div>

            {/* Language Picker */}
            <div className="flex justify-center">
                <select
                    className="p-2 bg-blue-100 rounded-md"
                    value={language}
                    onChange={(e) => setLanguage(Number(e.target.value))}
                >
                    <option value={2}>{testDetails?.screen_texts?.language_texts?.language1}</option>
                    <option value={1}>{testDetails?.screen_texts?.language_texts?.language2}</option>
                </select>
            </div>

            {/* Instructions Section */}
            <div className="p-4 bg-gray-100 rounded-md">
                <p dangerouslySetInnerHTML={{
                    __html: language === 2 
                        ? testDetails?.instructions?.instruction_english 
                        : testDetails?.instructions?.instruction_hindi 
                }} />
            </div>

            {/* Buttons Section */}
            <div className="flex space-x-3">
                {/* Primary Button */}
                <button
                    className="w-full bg-blue-600 text-white py-2 rounded-md"
                    onClick={openTestWindow}
                >
                    {testDetails?.screen_texts?.button_text}
                </button>

                {/* Secondary Button (Only if text is available) */}
                {testDetails?.screen_texts?.button2_text && (
                    <button
                        className="w-full border border-blue-600 text-blue-600 py-2 rounded-md"
                        onClick={openTestWindow}
                    >
                        {testDetails?.screen_texts?.button2_text}
                    </button>
                )}
            </div>
        </div>
    );
};

export default TestSeriesInstruction;
