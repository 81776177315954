import React, { useState } from "react";
import styles from "./TabContentElement.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faSpinner,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import { faAndroid, faApple, faWindows
 } from '@fortawesome/free-brands-svg-icons';

 import '@fortawesome/fontawesome-free/css/all.min.css';

import { ApiHitPost } from "../../../../Services/ApiHit";

const TabContentElement = ({
  productId,
  currentActiveTabId,
  currentActiveTabType,
  currentActiveTabData,
  setCurrentActiveTabData,
  currentLevel,
  setCurrentLevel,
}) => {
  // State variables
  const [loading, setLoading] = useState(false);
  const [currentParentId, setCurrentParentId] = useState(0);
  const [previousParentId, setPreviousParentId] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [showPopup, setShowPopup] = useState(false);

  const [testDetails, setTestDetails] = useState(0);
  
  const [error, setError] = useState(null);

  // Function to handle back navigation
  function backOnClick() {
    categoryOnClick(previousParentId, currentLevel - 1);
  }

  // Function to handle category click
  const categoryOnClick = (categoryId, levelToSend) => {
    if (currentActiveTabId) {
      setLoading(true);
      ApiHitPost("/product/get_product_tab_data", {
        product_id: parseInt(productId),
        tab_id: currentActiveTabId,
        level: levelToSend,
        parent_id: categoryId,
      })
        .then((response) => {
          if (response.status === "SUCCESS") {
            if (levelToSend - 1 > 0) {
              setPreviousParentId(currentParentId);
            }
            setCurrentLevel(levelToSend);
            setCurrentParentId(categoryId);
            setCurrentActiveTabData(response.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching country codes:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  // Function to open PDF in a new window
  const pdfOnClick = (url) => {
    window.open(url);
  };

  // Function to toggle FAQ answers
  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const onImageError = (e) => {
    e.target.src = "/drishti_favicon.ico";
  };

  const downloadDlaApp = () => {
    setShowPopup(true);
  };

  
  const loadTestInstruction = async (test_series_id, productId, currentActiveTabId, is_reattempt) => {
    setLoading(true);
    setError(null);

    var reattumpt_val = 0;
    if(is_reattempt){
      reattumpt_val = 1
    }
    window.location.href = `/test-series-instruction?test-series-id=${test_series_id}&product-id=${productId}&is-reattempt=${reattumpt_val}&tab-id=${currentActiveTabId}&internal_redirection=0&language=1`;

    // Ensure the page reloads after redirection
   
};



const startTestRedirection = (test_series_id, productId, currentActiveTabId, is_reattempt) => {
  const token = localStorage.getItem("dla_web_student_u");

  // Get full screen width & height
  const width = window.screen.width || window.innerWidth;
  const height = window.screen.height || window.innerHeight;

  // Open a new test window

//   let newWindow = window.open(
//     `http://localhost:5009/api/v1/test_series/pts/engine?test-series-id=${test_series_id}&product-id=${productId}&is-reattempt=${is_reattempt}&tab-id=${currentActiveTabId}&internal_redirection=0&language=1&token=${token}`,
//     "_blank",
//     `width=${width},height=${height},top=0,left=0,toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=no`
// );

    const basePath = "/product-information/";

    if (!window.location.pathname.includes(basePath)) {
      window.history.pushState(null, "", `${basePath}${productId}`);
    }

    window.location.href = `${basePath}${productId}`;
    var reattumpt_val = 0;
    if(is_reattempt){
      reattumpt_val = 1
    }
  
  let newWindow = window.open(
      `https://www.drishtilearningapp.com/api/v1/test_series/pts/engine?test-series-id=${test_series_id}&product-id=${productId}&is-reattempt=${reattumpt_val}&tab-id=${currentActiveTabId}&internal_redirection=0&language=1&token=${token}`,
      "_blank",
      `width=${width},height=${height},top=0,left=0,toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=no`
  );

  if (newWindow) {
      let minimizeCount = 0; // Track minimize attempts

      // Move window to full screen position
      newWindow.moveTo(0, 0);
      newWindow.resizeTo(width, height);

      // Enforce focus on the new window
      let focusInterval = setInterval(() => {
          if (newWindow.closed) {
              clearInterval(focusInterval);
          } else {
              newWindow.focus();
          }
      }, 100);

      // Detect if the test window loses focus
      newWindow.onblur = function () {
          minimizeCount++;

          if (minimizeCount >= 3) { // Close the test after 3 minimize attempts
              newWindow.close();
          } else {
              newWindow.focus();
          }
      };

      // Prevent closing the main window until test is complete
      window.onbeforeunload = function () {
          if (!newWindow.closed) {
              return "You must complete the test before leaving!";
          }
      };
  } else {
      alert("Popup blocked! Please allow pop-ups for this site.");
  }
};

const formatDateTime = (dateString) => {
  if (!dateString) return "N/A"; // Handle null or undefined dates
  const date = new Date(dateString);
  return isNaN(date.getTime())
    ? "Invalid Date"
    : date.toLocaleString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
};



  const closePopup = () => {
    setShowPopup(false);
  };

  const redirectToPlayStore = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.drishti.academy.app&hl=en_US",
      "_blank"
    );
  };

  const redirectToAppStore = () => {
    window.open("https://apps.apple.com/in/app/drishti-learning-app/id1604411528", "_blank");
  };

  const redirectToWindowsStore = () => {
    window.open("https://dla.drishtiias.com/windows", "_blank");
  };

  return (
    <>
      {/* Loading spinner */}
      {loading && (
        <span className={styles.backIconDiv}>
          <FontAwesomeIcon className={styles.spinnerIcon} icon={faSpinner} />
          <span>Loading...</span>
        </span>
      )}

      {/* Back button */}
      {!loading && currentLevel > 0 && (
        <span className={styles.backIconDiv} onClick={backOnClick}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span>Back</span>
        </span>
      )}

      {/* For OVERVIEW TAB */}
      {currentActiveTabType === 2 &&
        currentActiveTabData.content_list &&
        currentActiveTabData.content_list.length > 0 && (
          <div className={styles.oneContainerDiv}>
            {currentActiveTabData.content_list.map((oneQuestion, index) => (
              <div key={index} className={styles.OneFaq}>
                <div
                  className={styles.OneFaqQuestion}
                  onClick={() => toggleAnswer(index)}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: oneQuestion.heading,
                    }}
                  ></div>
                  {/* <span>{oneQuestion.heading}</span> */}
                  <FontAwesomeIcon
                    icon={activeIndex === index ? faChevronUp : faChevronDown}
                    className={activeIndex === index ? styles.iconRotate : ""}
                  />
                </div>
                <div
                  className={`${styles.OneFaqAnswer} ${
                    activeIndex === index ? styles.open : ""
                  }`}
                >
                  <div
                    className={styles.OneFaqAnswerContent}
                    dangerouslySetInnerHTML={{
                      __html: oneQuestion.description,
                    }}
                  ></div>
                </div>
              </div>
            ))}
          </div>
        )}

      {/* For [PDF/VIDEO/TEST] categories */}
      {[1, 3, 4].includes(currentActiveTabType) &&
        currentActiveTabData.category_list &&
        currentActiveTabData.category_list.length > 0 && (
          <div className={styles.oneContainerDiv}>
            {currentActiveTabData.category_list.map((oneCategory, index) => (
              <div
                key={index}
                className={styles.oneCategorydiv}
                onClick={() =>
                  categoryOnClick(oneCategory.category_id, currentLevel + 1)
                }
              >
                <img
                  src={oneCategory.category_image_icon}
                  alt=""
                  className={styles.oneContentImage}
                  onError={onImageError}
                />
                <span className={styles.oneContentTotalText}>
                  <span className={styles.oneContentText}>
                    {oneCategory.category_title}
                  </span>
                  <br />
                  <span className={styles.oneContentSubText}>
                    {oneCategory.category_sub_text}
                  </span>
                </span>
              </div>
            ))}
          </div>
        )}

      {/* For PDF [END NODE] */}
      {currentActiveTabType === 1 &&
        currentActiveTabData.content_list &&
        currentActiveTabData.content_list.length > 0 && (
          <div className={styles.oneContainerDiv}>
            {currentActiveTabData.content_list.map((oneContent, index) => (
              <div
                key={index}
                className={styles.oneContentdiv}
                onClick={() => pdfOnClick(oneContent.pdf_file_url)}
              >
                <img
                  src={oneContent.pdf_thumbnail_url}
                  alt=""
                  className={styles.oneContentImage}
                  onError={onImageError}
                />
                <span className={styles.oneContentText}>
                  {oneContent.pdf_title}
                </span>
              </div>
            ))}
          </div>
        )}

      {/* For VIDEO [END NODE] */}
      {currentActiveTabType === 3 &&
        currentActiveTabData.content_list &&
        currentActiveTabData.content_list.length > 0 && (
          <div className={styles.oneContainerDiv}>
            {currentActiveTabData.content_list.map((oneContent, index) => (
            
              <div
                key={index}
                className={styles.oneContentdiv}
                onClick={downloadDlaApp}
              >
                <img
                  src={oneContent.video_thumbnail_url}
                  alt=""
                  className={styles.oneContentImage}
                  onError={onImageError}
                />
                <span className={styles.oneContentText}>
                  {oneContent.video_title}
                </span>
              </div>
            ))}
          </div>
        )}
      
      {/* For TEST [END NODE] */}
      {/* // Important Information For Test Series Management [With Hierarchy]
          // [Mains Tests]
          // current_status: 
              // 0 => Upcoming
              // 1 => UnAttempted
              // 2 => Attempted + Unchecked
              // 3 => Attempted + Checked
              // 4 => Expired

          // [Prelims Tests]
          // current_status: 
              // 0 => Upcoming
              // 1 => UnAttempted
              // 2 => Resume (Partially Attempted)
              // 3 => Attempted + Result Await
              // 4 => Attempted + Result Declared
              // 5 => Expired */}

{currentActiveTabType === 4 &&
  currentActiveTabData.content_list &&
  currentActiveTabData.content_list.length > 0 && (
    <div className={styles.customContainerDiv}>
      {currentActiveTabData.content_list.map((oneContent, index) => (
        <div key={index} className={styles.customContentDiv}>

          {/* Test Name & Icon */}
          <div className={styles.customContentHeader}>
            <img
              src="/drishti_favicon.ico"
              alt="Test Series"
              className={styles.customContentImage}
              onError={onImageError}
            />
           <span className={styles.customContentText}>
  {oneContent.test_series_name}
</span>

          </div>

          {/* Test Information or Evaluation Details */}
          <div className="customTestDetailsContent">
            {oneContent.current_status === 4 ? (
              <div className="customEvaluationDetails">
             <span className={styles.customEvaluationHeading}>
              <i className="fas fa-poll"></i> Evaluation Details
            </span>


                <ul className="customEvaluationList">
                  <li>
                    <i className="fas fa-check-circle"></i> 
                    <span className="customLabel">  Correct:</span> 
                    <span className="customValue">{oneContent.evaluated_data.correct_count}</span>
                  </li>
                  <li>
                    <i className="fas fa-times-circle"></i> 
                    <span className="customLabel">Incorrect:</span> 
                    <span className="customValue">{oneContent.evaluated_data.incorrect_count}</span>
                  </li>
                  <li>
                    <i className="fas fa-star"></i> 
                    <span className="customLabel">Marks:</span> 
                    <span className="customValue">{oneContent.evaluated_data.obtained_marks}</span>
                  </li>
                  <li>
                    <i className="fas fa-question-circle"></i> 
                    <span className="customLabel">Total Qs:</span> 
                    <span className="customValue">{oneContent.number_of_questions}</span>
                  </li>
                </ul>
              </div>
            ) : (
              <div className="customTestDetails">
                 <span className={styles.customEvaluationHeading}>
                  <i className="fas fa-info-circle"></i> Test Information
                </span>
                {/* <h3><i className="fas fa-info-circle"></i> <b>Test Information</b></h3> */}
                <ul className="customTestDetailsList">
                  <li>
                    <i className="fas fa-calendar-alt"></i> 
                    <span className="customLabel">  Start:</span> 
                    <span className="customValue">{formatDateTime(oneContent.start_date)}</span>
                  </li>
                  <li>
                    <i className="fas fa-barcode"></i> 
                    <span className="customLabel">  Code:</span> 
                    <span className="customValue">{oneContent.test_code}</span>
                  </li>
                  <li>
                    <i className="fas fa-calendar-times"></i> 
                    <span className="customLabel">  Expiry:</span> 
                    <span className="customValue">{formatDateTime(oneContent.end_date)}</span>
                  </li>
                  <li>
                    <i className="fas fa-question-circle"></i> 
                    <span className="customLabel">  Total Qs:</span> 
                    <span className="customValue">{oneContent.number_of_questions}</span>
                  </li>
                </ul>
              </div>
            )}
          </div>

          {/* Action Button */}
          <div className={styles.customActionButtonContainer}>
            {oneContent.current_status === 1 && (
              <button
                onClick={() => loadTestInstruction(oneContent.test_series_id, productId, currentActiveTabId, oneContent.can_reattempt)}
                className={`${styles.customStartTestButton} customWideButton`}
              >
                {oneContent.screen_texts.button_text}
              </button>
            )}

            {oneContent.current_status === 2 && (
              <button
                onClick={() => startTestRedirection(oneContent.test_series_id, productId, currentActiveTabId, oneContent.can_reattempt)}
                className={`${styles.customStartTestButton} customWideButton`}
              >
                {oneContent.screen_texts.button_text}
              </button>
            )}

            {oneContent.current_status === 3 && (
              <button
                // onClick={() => checkResultStatus(oneContent.test_series_id, productId, currentActiveTabId)}
                className={`${styles.customExpiringButton} customWideButton`}
              >
                Result Awaiting
              </button>
            )}

            {oneContent.current_status === 4 && !oneContent.can_reattempt &&  (
              <button
                onClick={() => startTestRedirection(oneContent.test_series_id, productId, currentActiveTabId, 0)}
                className={`${styles.customViewResultButton} customWideButton`}
              >
                {oneContent.screen_texts.button_text}
              </button>
            )}

    {oneContent.current_status === 4 && oneContent.can_reattempt && (
      <>
        <button
          onClick={() =>
            loadTestInstruction(
              oneContent.test_series_id,
              productId,
              currentActiveTabId,
              oneContent.can_reattempt
            )
          }
      className={`${styles.customStartTestButton} customWideButton`}
    >
      {oneContent.screen_texts.reattempt_button_text}
    </button>

    <button
      onClick={() =>
        startTestRedirection(
          oneContent.test_series_id,
          productId,
          currentActiveTabId,
          0
        )
      }
      className={`${styles.customViewResultButton} customWideButton`}
    >
      {oneContent.screen_texts.button_text}
    </button>
  </>
)}


          {oneContent.current_status === 5 && (
            <button
            
            className={`${styles.customExpiredButton} customWideButton`}
              
            >
                 {oneContent.screen_texts.status_text}
            </button>
          )}

          </div>

        </div>
      ))}
    </div>
  )}


    
      {/* Download App Popup */}
      {showPopup && (
        <div className={styles.popupOverlay}>
          <div className={styles.popupContent}>
            <FontAwesomeIcon
              icon={faTimes}
              className={styles.closeIcon}
              onClick={closePopup}
            />
            <h2>Download Drishti Learning App</h2>
            <p>Experience the new Drishti Learning App on Android, iOS and Windows!!</p>
            <div className={styles.downloadButtons}>
              <button onClick={redirectToPlayStore} className={styles.androidButton}>
                <FontAwesomeIcon icon={faAndroid} />
                <span className={ styles.TextAndroid }>Android</span>
              </button>
              <button onClick={redirectToAppStore} className={styles.iosButton}>
                <FontAwesomeIcon icon={faApple} />
                <span className={styles.TextIos}>iOS</span>
              </button>
              <button onClick={redirectToWindowsStore} className={styles.windowsButton}>
                <FontAwesomeIcon icon={faWindows} />
                <span className={styles.WindowsText}>Windows</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TabContentElement;