import React, { useEffect, useState } from 'react';
import './ExploreResult.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faLanguage } from '@fortawesome/free-solid-svg-icons';
import { ApiHitPost } from "../../Services/ApiHit";
import {
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import Loader from '../Includes/Loader/Loader'

const ExploreResult = ({ isLoggedInUser }) => {

    const [exploreResultDataSkip, setExploreResultDataSkip] = useState([]);
    const [exploreResultDataEndNode, setExploreResultDataEndNode] = useState([]);
    const [exploreResultSelectedHierarchy, setExploreResultSelectedHierarchy] = useState('');
    const [breadcrumbsData, setBreadcrumbsData] = useState([]);
    const [loading, setLoading] = useState(false);

    const truncateTitle = (text, wordLimit) => {
        const words = text.split(' ');
        if (words.length > wordLimit) {
            return words.slice(0, wordLimit).join(' ') + '...';
        }
        return text;
    };

    function HandleBreadcrumbsVerticalClick(verticalId) {
        if(verticalId){
            localStorage.setItem("dla_web_student_selected_vertical",verticalId);
        }
        window.location = '/';
    }

    function HandleBreadcrumbsMiddleClick(NodeId) {
        let pathToBeUsed = [];
        for (let index = 0; index < breadcrumbsData.length; index++) {
            const oneData = breadcrumbsData[index];
            pathToBeUsed.push(oneData.id);
            if (oneData.id === NodeId) {
                break;
            }
        }

        if(pathToBeUsed.length > 0){
            localStorage.setItem("dla_web_student_selected_hierarchy",pathToBeUsed.join(','));
            window.location = '/explore';
        }
    }

    useEffect(() => {
        const dla_web_student_selected_hierarchy = localStorage.getItem('dla_web_student_selected_hierarchy');
        if (dla_web_student_selected_hierarchy === null) {
            window.location.href = '/';
        } else {
            setExploreResultSelectedHierarchy(dla_web_student_selected_hierarchy);
        }
    }, []);

    useEffect(() => {
        if (exploreResultSelectedHierarchy) { 
            const GetExploreResultData = async () => {
                setLoading(true);
                await ApiHitPost("/hierarchy/get_product_group_list", {
                    path: exploreResultSelectedHierarchy,
                    view_all: false
                }).then((response) => {
                    if(response.data.data_type == 2){
                        // End Node
                        setExploreResultDataEndNode(response.data.product_data);
                        setBreadcrumbsData(response.data.get_breadcrumbs_data_of_path);
                        localStorage.removeItem('dla_web_student_selected_hierarchy');
                    }else if(response.data.data_type == 1){
                        // Skip Screen
                        setExploreResultDataSkip(response.data.group_data);
                        setBreadcrumbsData(response.data.get_breadcrumbs_data_of_path);
                        localStorage.removeItem('dla_web_student_selected_hierarchy');
                    }else{
                        localStorage.removeItem('dla_web_student_selected_hierarchy');
                        window.location.href = '/';
                    }
                    
                }).catch((error) => {
                    console.error('Error fetching search results:', error);
                }).finally(() => {
                    setLoading(false);
                });
            }
            GetExploreResultData();
        }
    }, [exploreResultSelectedHierarchy]);

    const handleViewDetails = (productId) => {
        window.open(`/product-information/${productId}`);
    };

    const handleBuyNowDetails = (productId) => {
        try {
            if (isLoggedInUser) {
                window.open(`/pay/${productId}`);
            } else {
                localStorage.setItem('dla_web_student_redirect_back_to_login', `/pay/${productId}`);
                window.open(`/login`);
            }
        } catch (error) {
            console.log(error);
        } finally {
            
        }
    }

    if (loading) {
        return (
            <Loader />
        );
    }

    return (
        <>
            {
                exploreResultDataEndNode.length == 0 && exploreResultDataSkip.length == 0 
                &&
                <div className="course-list-container">
                    No Result Found!
                </div>
            }

            {
                exploreResultDataSkip.length > 0 
                &&
                <div className="course-list-container">
                    {
                        (breadcrumbsData.length > 0)
                        &&
                        <div className='HierarchyHorizontalDiv'>
                            {
                                breadcrumbsData.map((oneHierarchy, index) => (
                                    <div key={index} className='OneHierarchyHorizontalDiv'>
                                        {
                                            index > 0 
                                            &&
                                            <FontAwesomeIcon 
                                                key={`${index}_icon`}
                                                icon={faChevronRight}
                                            />
                                        }
                                        
                                        <img 
                                            src={oneHierarchy.category_thumbnail_image} 
                                            alt=""
                                            className='HierarchyIcon'
                                            onClick={(index+1) == breadcrumbsData.length ? () => {} : index == 0 ? () => {HandleBreadcrumbsVerticalClick(oneHierarchy.id)} : () => {HandleBreadcrumbsMiddleClick(oneHierarchy.id)}}
                                        />
                                        
                                        <span 
                                            onClick={(index+1) == breadcrumbsData.length ? () => {} : index == 0 ? () => {HandleBreadcrumbsVerticalClick(oneHierarchy.id)} : () => {HandleBreadcrumbsMiddleClick(oneHierarchy.id)}}
                                        >
                                            {oneHierarchy.category_name_english}
                                        </span>
                                    </div>
                                ))
                            }
                        </div>
                    }

                    {exploreResultDataSkip.map((oneProductList,index) => (
                        <div key={index} className="course-list-wrapper">
                            <div className="course-list-header">
                                <h2>{oneProductList.title_heading}</h2>
                                {/* <a href="#" className="view-all">View All</a> */}
                            </div>
                            <div className="course-grid">
                                {oneProductList.child_products.map((course) => (
                                    <div key={course.product_id} className="course-card">
                                        <div className="course-info">
                                            <div className="course-banner">
                                                <img src={course.desc_header_image} alt={course.product_title_english} />
                                            </div>
                                            <div className="course-type">{truncateTitle(course.product_title_english, 3)}</div>
                                            <div className="category-main-div">
                                                <span className="category-btn">
                                                    {course.product_category_type_name}
                                                </span>
                                            </div>
                                            <br />
                                            <div className="course-details">
                                                <span>
                                                    <FontAwesomeIcon icon={faCalendar} style={{ color: '#3e67be' }} /> Validity: {course.validity} Months
                                                </span>
                                                <span>
                                                    <FontAwesomeIcon icon={faLanguage} style={{ color: '#3e67be' }} /> Medium: {course.product_lang}
                                                </span>
                                            </div>
                                            <div className="course-pricing">
                                                <span className="course-discounted-price">₹{course.product_selling_price}</span>
                                                <span className="course-original-price">₹{course.product_mrp}</span>
                                            </div>
                                            <div className="course-actions">
                                                <button
                                                    className="course-view-details"
                                                    onClick={() => handleViewDetails(course.product_id)}
                                                >
                                                    View Details
                                                </button>
                                                <button 
                                                    className="course-buy-now"
                                                    onClick={() => handleBuyNowDetails(course.product_id)}
                                                >
                                                    Buy Now
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            }


            {
                exploreResultDataEndNode.length > 0 
                &&
                <div className="course-list-container">
                    {
                        (breadcrumbsData.length > 0)
                        &&
                        <div className='HierarchyHorizontalDiv'>
                            {
                                breadcrumbsData.map((oneHierarchy, index) => (
                                    <div key={index} className='OneHierarchyHorizontalDiv'>
                                        {
                                            index > 0 
                                            &&
                                            <FontAwesomeIcon 
                                                key={`${index}_icon`}
                                                icon={faChevronRight}
                                            />
                                        }
                                        
                                        <img 
                                            src={oneHierarchy.category_thumbnail_image} 
                                            alt=""
                                            className='HierarchyIcon'
                                            onClick={(index+1) == breadcrumbsData.length ? () => {} : index == 0 ? () => {HandleBreadcrumbsVerticalClick(oneHierarchy.id)} : () => {HandleBreadcrumbsMiddleClick(oneHierarchy.id)}}
                                        />
                                        
                                        <span 
                                            onClick={(index+1) == breadcrumbsData.length ? () => {} : index == 0 ? () => {HandleBreadcrumbsVerticalClick(oneHierarchy.id)} : () => {HandleBreadcrumbsMiddleClick(oneHierarchy.id)}}
                                        >
                                            {oneHierarchy.category_name_english}
                                        </span>
                                    </div>
                                ))
                            }
                        </div>
                    }
                    
                    <div className="course-list-wrapper">
                        <div className="course-grid">
                            {exploreResultDataEndNode.map((course) => (
                                <div key={course.product_id} className="course-card">
                                    {/* ribbon added by @author Satyam */}
                                    {/* <div className="dla-ribbon">
                                        <span className="dla-ribbon-text">40% OFF</span>
                                    </div> */}
                                    <div className="course-info">
                                        <div className="course-banner">
                                            <img src={course.desc_header_image} alt={course.product_title_english} />
                                        </div>
                                        <div className="course-type">{truncateTitle(course.product_title_english, 6)}</div>
                                        <div className="category-main-div">
                                            <span className="category-btn">
                                                {course.product_category_type_name}
                                            </span>
                                        </div>
                                        <br />
                                        <div className="course-details">
                                            <span>
                                                <FontAwesomeIcon icon={faCalendar} style={{ color: '#3e67be' }} /> Validity: {course.validity} Months
                                            </span>
                                            <span>
                                                <FontAwesomeIcon icon={faLanguage} style={{ color: '#3e67be' }} /> Medium: {course.product_lang}
                                            </span>
                                        </div>
                                        <div className="course-pricing">
                                            <span className="course-discounted-price">₹{course.product_selling_price}</span>
                                            <span className="course-original-price">₹{course.product_mrp}</span>
                                        </div>
                                        <div className="course-actions">
                                            <button
                                                className="course-view-details"
                                                onClick={() => handleViewDetails(course.product_id)}
                                            >
                                                View Details
                                            </button>
                                            <button 
                                                className="course-buy-now"
                                                onClick={() => handleBuyNowDetails(course.product_id)}
                                            >
                                                Buy Now
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default ExploreResult;
